// import {React} from 'reat';
import { useState, useEffect, useMemo } from "react";
// import likeButton from "../../../assets/images/social/Animation/Like.gif";

import animatedLikeButton from "../../../assets/images/social/Animation/LIKE.webp";

import unselectedLikeButton from "../../../assets/images/social/Like.svg";

import selectedLikeButton from "../../../assets/images/social/Like - Selected.svg";

import shareButton from "../../../assets/images/social/Share.svg";

import { ContentInfoScreen as events } from "../../../analytics/events";
import { useSelector, useDispatch } from "react-redux";
import { RWebShare } from "react-web-share";
import { selectItem } from "../../../utils/redux/actions/subscription.action";
import ApiCall from "../../../utils/network/apiCalls";
import HelperFunctions from "../../../utils/HelperFunctions";
import { useAuth0 } from "@auth0/auth0-react";

const SocialButtons = () => {
  const dispatch = useDispatch();
  const { subscriptions, profile, language } = useSelector((state) => state);
  const apiCall = new ApiCall();
  const { network } = useSelector((state) => state);
		const { playInstanceItems } = useSelector((state) => state?.items);
    const { isAuthenticated } = useAuth0();

  console.log("SocialButtons -> network", language);

  const selectedItem = subscriptions?.selectedItem;
  const shareUrl = useMemo(
    () =>
      `${HelperFunctions.generateShareLink()}?id=${
        selectedItem?.ID?.id ?? ""
      }&region=${String(selectedItem?.Region ?? "").toLowerCase()}&lang=${
        language?.chosenLanguage?.code ?? ""
      }${HelperFunctions.getShareEnvParam() ?? ""}`,
    [selectedItem],
  );

  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    if (showAnimation) {
      setTimeout(() => {
        setShowAnimation(false);
      }, 500);
    }
  }, [showAnimation]);

  const setActivaFunc = async () => {
    if (selectedItem?.ID?.id && profile?.data?.msisdn) {
      try {
        await apiCall.toggleLikeContent(
          profile?.data?.msisdn,
          selectedItem?.ID?.id,
        );
        dispatch(selectItem({ ...selectedItem, liked: true }));
        setShowAnimation(true);
        dispatch({
          type: "ANALYTICS/TRACK_EVENT",
          event: events.like_tap(
            network?.call?.headers?.isoRegion,
            network?.call?.headers.environment,
            selectedItem?.itemType,
            selectedItem?.title,
            selectedItem?.subscriptionType,
            selectedItem?.vendorId,
            selectedItem?.vendor?.title,
          ),
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const unsetActivaFunc = async () => {
    if (selectedItem?.ID?.id && profile?.data?.msisdn) {
      try {
        await apiCall.toggleLikeContent(
          profile?.data?.msisdn,
          selectedItem?.ID?.id,
        );
        dispatch(selectItem({ ...selectedItem, liked: false }));
      } catch (e) {
        console.log(e);
      }
    }
  };

  const shareContentFunc = () => {};

  return (
    <div style={{ display: "flex", marginLeft: 0, marginBottom: 10, gap: 24 }}>
      <div
        style={{
          height: 39,
          display:
            playInstanceItems?.message === "full" && isAuthenticated
              ? "flex"
              : "none",
          justifyContent: "space-between",
        }}>
        <div style={{ position: "relative", height: 24 }}>
          <img
            onClick={() =>
              selectedItem?.liked ? unsetActivaFunc() : setActivaFunc()
            }
            style={
              showAnimation //TODO: Double check
                ? {
                    position: "absolute ",
                    width: 62,
                    height: 62,
                    marginTop: -24,
                    marginLeft: -24,
                  }
                : { width: 22, height: 22, position: "absolute " }
            }
            src={
              showAnimation
                ? animatedLikeButton
                : selectedItem?.liked
                ? selectedLikeButton
                : unselectedLikeButton
            }
          />
        </div>
        <p style={{ fontSize: 12, marginTop: "auto", lineHeight: 1 }}>Like</p>
      </div>

      {/* <p style={{fontSize:"10px", color: activeLike?"#FFCB05":"#fff"}} >Like</p> */}

      <div style={{ marginLeft: 0 }}>
        <div style={{ textAlign: "center" }}>
          <RWebShare
            data={{
              text: `Check out what I found on MTN Play, ${
                selectedItem?.title ?? ""
              }.`,
              url: shareUrl,
              title: selectedItem?.title ?? "",
            }}
            onClick={() => shareContentFunc()}>
            <img style={{ width: 22, height: 22 }} src={shareButton} />
          </RWebShare>
          <p style={{ fontSize: 12, lineHeight: 1 }}>Share</p>
        </div>

        {/* <p style={{fontSizep:"10px"}} >Share</p> */}
      </div>
    </div>
  );
};


export default SocialButtons;
