import { useState, useEffect } from "react";
import {
  Button,
  Page,
  ModalButtons,
  ModalText,
  Modal,
} from "../../shared/components";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import {
    buyCurrentItem,
   saveConsent,
   clearSubscription,
   clearConsent,
} from "../../utils/redux/actions/subscription.action";
import ApiCall from "../../utils/network/apiCalls";
import { PWA_ROUTES } from "../../constants/appConstants";

//  import { useSearchParams } from "react-router-dom";

// import { StyledConsentScreen } from "./StyledConsentScreen.styles";



const ConsentScreen = () => {

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [subscriptionAttempt, setSubscriptionAttempt] = useState(false);
  const [subscriptionRenew] = useState(false);
  const navigate = useNavigate();

  //  const [searchParams] = useSearchParams();
  

  const {
    selectedItem,
    selectedCharge,
    // paymentMethods,
    paymentStatusInfo,
    error,
    consent,
  } = useSelector((state) => state?.subscriptions);
  const { network, profile, language } = useSelector((state) => state);
  

  const service = selectedItem;

  const CurrentService = {
    logo: service?.service?.logo? `url(${service?.service?.logo})`:`url(${service?.logo})`,
    name: service?.service?.name? `url(${service?.service?.name})`:`url(${service?.name})`,
  };


  useEffect(() => {
      if(consent?.StatusCode === 200) {
        confirm();
        dispatch(clearConsent());
      } else if(consent?.StatusCode === 400 || consent?.StatusCode === 500 || consent?.StatusCode === 404) {
       navigate(PWA_ROUTES.GENERIC_ERROR);
      }
  }, [consent]);

  useEffect(() => {
    if (paymentStatusInfo == null) return;

    if (paymentStatusInfo?.Message?.statusCode !== "") {
      navigate(PWA_ROUTES.TRANSACTION_RES);
      setSubscriptionAttempt(false);
    } else {
      dispatch(clearSubscription());

      navigate(PWA_ROUTES.GENERIC_ERROR);
    }
  }, [paymentStatusInfo]);

  
	useEffect(() => {
    if (error) {
      navigate(PWA_ROUTES.GENERIC_ERROR);
    }
  }, [error]);

    const confirm = () => {
    if (network?.call?.headers?.region) {
      const apiCall = new ApiCall(network?.call?.headers);
      const postPayload = {
        ...selectedCharge,
        subscriptionRenew,
         // accessToken: accessToken,
        msisdn: profile.data.msisdn,
      };
      dispatch(
        buyCurrentItem(apiCall, postPayload, profile.data.msisdn),
      );
    }
  };
  
  const performConsent = () => {
    setSubscriptionAttempt(true);
    const apiCall = new ApiCall(network?.call?.headers);
    const payLoadSend = {
      "subscriptionId": selectedCharge.subscriptionId
    };
    dispatch(saveConsent(apiCall,payLoadSend));
  };


  return (
    <Page isBottomBar={false}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 24,
          paddingTop: 88,
          gap: 18,
        }}>
        <div
          style={{
            backgroundImage: CurrentService.logo,
            width: "100%",
            height: 112,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        />
        <p
          style={{
            textAlign: "center",
            fontSize: 14,
            lineHeight: "20px",
            letterSpacing: 0.25,
            fontFamily: "Roboto",
            marginBottom: 22,
          }}>
          {`${language?.chosenPack?.language?.COPY?.SCREENS?.consent?.consent_msg}
           ${CurrentService.name} 
           ${language?.chosenPack?.language?.COPY?.SCREENS?.consent?.consent_msg2}`}
        </p>

        <Button
          loading={subscriptionAttempt}
          clickHandler={performConsent}
          caption= {language?.chosenPack?.language?.COPY?.SCREENS?.consent?.accept_btn}
        />
        <Button
          caption={language?.chosenPack?.language?.COPY?.SCREENS?.consent?.decline_btn}
          isOutline={true}
          clickHandler={() => setShowModal(true)}
        />
      </div>
      <Modal
        onClose={() => setShowModal(false)}
        show={showModal}
        title={language?.chosenPack?.language?.COPY?.SCREENS?.consent?.decline_consent_request}>
        <ModalText
          firstSentence={`${language?.chosenPack?.language?.COPY?.SCREENS?.consent?.decline_consent_msg} ${CurrentService.name} ${language?.chosenPack?.language?.COPY?.SCREENS?.consent?.decline_consent_msg2}.`}
        />
        <ModalButtons
          onClose={() => {
            setShowModal(false);
          }}
          primaryActionButtonText={language?.chosenPack?.language?.COPY?.SCREENS?.consent?.decline_btn}
          onPrimaryAction={() => {
            //When user declines consent
            setShowModal(false);
            navigate(PWA_ROUTES.HOME);
          }}
        />
      </Modal>
    </Page>
  );
};

export default ConsentScreen;
