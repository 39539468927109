import { motion } from "framer-motion";
import styled from "styled-components";

const StyledModalText = styled(motion.div)`
  	font-family: MTNBrighterSansRegular;
	font-style: normal;
	font-weight: normal;
	font-size: ${({isPadding}) => isPadding ? "16px" : "12px"};
	line-height: ${({isPadding}) => isPadding ? "18.75px" : "14px"};
	/* text-align: justify; */
	padding: ${({isPadding}) => isPadding ? "24px" : "0px"};
`;

const ModalText = ({firstSentence, clickHandler, secondSentence, thirdSentence, isPadding}) => {
	return (
		<StyledModalText onClick={clickHandler} isPadding={isPadding}>
			{firstSentence && (<p>{firstSentence}</p>)}
			{secondSentence && (<p>{secondSentence}</p>)}
			{thirdSentence && (<p>{thirdSentence}</p>)}
		</StyledModalText>
	);
};

export default ModalText;
