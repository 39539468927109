import { motion } from "framer-motion";
import {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { unsubscribe } from "../../../utils/redux/actions/subscription.action";

const StyledModalButtons = styled(motion.div)`
  width: 100%;
  padding-top: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fbc800;

  .btn {
    font-family: MTNBrighterSansRegular;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    padding: 16px;

    display: flex;
    align-items: center;
    text-align: center;
  }
`;

const ModalButtons = ({
  confirmButtonText,
  isConfirmed,
  status,
  onClose,
  error,
  okButtonText,
  confirmIn,
  primaryActionButtonText,
  onPrimaryAction,
}) => {
  const dispatch = useDispatch();
  const { network, subscriptions } = useSelector((state) => state);

  const confirm = () => {
    // onClose();
    dispatch(
      unsubscribe(network.call, subscriptions?.selectedItem?.billingType),
    );
  };

  useEffect(() => {}, [status, isConfirmed, error]);

  return (
    <StyledModalButtons>
      <span onClick={() => onClose()} className="btn">
        {okButtonText ? okButtonText : "cancel"}
      </span>
      {confirmIn && (
        <span
          onClick={() => {
            confirm();
          }}
          className="btn">
          {confirmButtonText || "confirm"}
        </span>
      )}
      {primaryActionButtonText && (
        <span
          onClick={() => {
            if (onPrimaryAction) onPrimaryAction();
          }}
          className="btn">
          {primaryActionButtonText}
        </span>
      )}
    </StyledModalButtons>
  );
};

export default ModalButtons;