import { motion } from "framer-motion";
import styled from "styled-components";
export const StyledIosPopup = styled(motion.div)`

    display: flex;
justify-content: center;
    .popUpImage {
     height: 60px;
     width:  60px;
    }

    .upper-desc {
        position: absolute;
        align-self: center;
        margin-top: 110px;

        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
    }

    .lower-desc {
        font-family: 'MTNBrighterSansRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.444444px;
        color: #000000;
    }
    .lower-desc-low {
        font-family: 'MTNBrighterSansRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.444444px;
        color: #000000;
        padding-top: 20px;
    }
    .upper {
        background: #000000;
        height: 190px;
        width:  280px;
        display: flex;
        padding-top: 20px;
        justify-content: center;
        border-radius: 8px 8px 0px 0px;
        font-weight: bold;
    }
    .lower {
        display: flex;

        background: #fff;
        height: 169px;
        width:  280px;
        border-radius: 0px 0px 8px 8px;
        padding: 24px 24px 24px 24px;
    }

	.tag {

		min-height: 52px;
		min-width: 164px;
		background: #111111;
		padding: 4px;
		margin-bottom: 12px;
		border-radius: 0 0 8px 8px;
		font-size: ${({theme}) => theme?.size?.f1 ?? "8px"};	 
		font-style: normal;
		font-weight: 400;
		font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
	
		padding-left: 10px;
		padding-top: 10px;
  }

  .tag-desc{
	font-family: 'MTN Brighter Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #FFFFFF;
  }

  .tag-sub-desc{
	font-family: 'MTNBrighterSansRegular';
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
	display: flex;
	align-items: center;
	text-transform: capitalize;
  }

  .tag-description-sub {

	display: flex;
	justify-items: center;
	justify-content: center;

	padding: 6px;

	background: #FFCB05;
	color: #111111;
	font-family: 'MTNBrighterSansRegular';
	font-style: normal;
	font-weight: bold;
	
	line-height: 12px;
	text-transform: capitalize;

  }

  .tag-description-sub2 {

	display: flex;
	justify-items: center;
	justify-content: center;

	padding: 6px;

	background: red;
	color: #111111;
	font-family: 'MTNBrighterSansRegular';
	font-style: normal;
	font-weight: bold;
	
	line-height: 12px;
	text-transform: capitalize;

  }


  .tag-description-free {

	display: flex;
	justify-items: center;
	justify-content: center;

	padding: 6px;
	background: #111111;
	color: #FFFFFF;;
	border-radius: 2px 0px 0px 2px;
	font-family: 'MTNBrighterSansRegular';
	font-style: normal;
	font-weight: bold;
	
	line-height: 12px;
	text-transform: capitalize;

  }

  .tag-description-once {

	display: flex;
	justify-items: center;
	justify-content: center;

	padding: 6px;

	background: #111111;
	color: #FFCB05;
	border-radius: 2px 0px 0px 2px;
	font-family: 'MTNBrighterSansRegular';
	font-style: normal;
	font-weight: bold;
	
	line-height: 12px;
	text-transform: capitalize;

  }




  .metadata {
	  margin-top: 12px;
	  min-height: 20px
	  max-width: 170px;
	  line-height: 12px;
	  font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
	  font-size: ${({theme}) => theme?.size?.f2 ?? "10px"};	  
	  font-style: normal;
	  font-weight: 500;
	  display: flex;
	  flex-direction: column;
	  text-transform: capitalize;

	  & span {
		  font-size: ${({theme}) => theme?.size?.f1 ?? "8px"};	  
	      line-height: 9px;
		  font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
	  }
  }

  .thumbnail {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	font-size: 10px;
	font-family: 'MTNBrighterSansRegular';
	font-style: normal;
	font-weight: bold;

	  };

      .thumbnail2 {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        font-size: 10px;
        font-family: 'MTNBrighterSansRegular';
        font-style: normal;
        font-weight: bold;
    
          };

	  

	  background-image: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, 0) 40.00%, rgba(17, 17, 17, 0.4) 60.00%, rgba(17, 17, 17, 0.9) 87.08%, #111111 100%), ${({item}) => item?.imageUrl?.length > 0 ? `url(${item?.imageUrl})` : ""};
	   background-position: center;
       background-repeat: no-repeat;
       background-size: cover;
	   border-radius: 8px 8px 0 0;
	  min-height: 172px;
	  min-width:  170px;

	  
  }
`;