import {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import {PWA_ROUTES} from "../../constants/appConstants";
import {Menu, Page, TopNav} from "../../shared/components";
import {getAyoba} from "../../lib/microapp";
import { setLanguage } from "../../utils/redux/actions/language.action";
import { ManageScreen as events } from "../../analytics/events";
import { LANGUAGES } from "../../constants/languages";
// import zigiChatBotInit from "../../lib/zigiChatBot";

const ManageScreen = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [showAyobaBtn, setShowAyobaBtn] = useState(getAyoba());

  const { language } = useSelector((state) => state);
  const { playInstanceItems } = useSelector((state) => state?.items);
  const {network} = useSelector((state) => state);


  useEffect(() => {
    // zigiChatBotInit(
    //   window,
    //   document,
    //   "script",
    //   "https://chatbot-dev.mtn.com.gh/bm/embed/embed.js",
    //   "mtnbot",
    //   "web",
    //   "",
    //   null,
    //   () => {
    //     zigiClick();
    //   },
    // );

    if (language?.chosenLanguage !== "") {
    } else {
      _setLanguage(LANGUAGES[network.call.headers.region][0]);
    }

    if (!getAyoba()) {
      setShowAyobaBtn(false);
    }

    dispatch({
      type: "ANALYTICS/TRACK_EVENT",
      event: events.manage_screen(
        network?.call?.headers?.isoRegion,
        network?.call?.headers.environment,
      ),
    });
  }, []);

  useEffect(() => {
    getAccessTokenSilently()
      .then(() => {})
      .catch((e) => {
        console.log(e);
      });
  }, [user]);

  const _setLanguage = (e) => {
    dispatch(setLanguage(e));
  };

  // const logout = (e) => {

  // 	dispatch({
  // 		type: "ANALYTICS/TRACK_EVENT",
  // 		event: events.logout,
  // 	});

  // };

  // const zigiClick = () => {
  //   dispatch({
  //     type: "ANALYTICS/TRACK_EVENT",
  //     event: events.zigiClick,
  //   });
  // };

  return (
    <>
      {/* <DirectionProvider direction={language?.chosenLanguage === 'arabic' ? DIRECTIONS.RTL:DIRECTIONS.LTR}> */}
      <Page isBottomBar={true}>
        <TopNav
          title={
            language?.chosenPack?.language?.COPY?.SCREENS?.manage?.screen_title
          }
          enableSearch={false}
        />
        {playInstanceItems?.message === "full" && (
          <Menu
            title={
              language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections
                ?.my_profile
            }
            clickRoute={PWA_ROUTES.PROFILE}
            description={
              language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections
                ?.my_profile_sub
            }
          />
        )}
        {playInstanceItems?.message === "full" && (
          <Menu
            title={
              language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections
                ?.subscription
            }
            clickRoute={PWA_ROUTES.SUBSCRIPTIONS}
            description={
              language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections
                ?.subscription_sub
            }
          />
        )}
        {/* <Menu
					title={language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections?.purchases}
					clickRoute={PWA_ROUTES.PURCHASES}
					description={language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections?.purchases_sub}
				/> */}
        <Menu
          title={language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections?.language}
          clickRoute={PWA_ROUTES.LANGUAGES}
          description={language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections?.language_sub}
        />
        <Menu
          title={
            language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections
              ?.help
          }
          clickRoute={PWA_ROUTES.FAQ}
          description={
            language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections
              ?.help_sub
          }
        />
        <Menu
          title={
            language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections
              ?.terms
          }
          clickRoute={PWA_ROUTES.TERMS_AND_CONDITIONS}
          description={
            language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections
              ?.terms_sub
          }
        />

        <Menu
          title={language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections?.privacy}
          clickRoute={PWA_ROUTES.PRIVACY_NOTICE}
          description={language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections?.privacy_sub}
        />

        {playInstanceItems?.message === "full" &&
        !showAyobaBtn &&
        isAuthenticated ? (
          <Menu
            title={
              language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections
                ?.logout
            }
            clickRoute={"#"}
            description={
              language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections
                ?.logout_sub
            }
          />
        ) : null}

        {/* {showAyobaBtn? <Menu
        title="Testi Video On Ayobas"
        clickRoute={PWA_ROUTES.TEST_AYOBA_VIDEO}
        description="Clears your profile details."
      />:null } */}

        {/* {showAyobaBtn? <Menu
        title="Test MSISDN On Ayobas"
        clickRoute={PWA_ROUTES.TEST_AYOBA_MSISDN}
        description="Clears your profile details."
      />: null } */}

        {/* <div style={styles}>
      <Accordion transition transitionTimeout={200}>
        <AccordionItem header="CHOOSE LANGUAGE">
          <div style={{marginTop:10, padding: 24}}>
          <div id="group1">
            <div style={{ display:'flex', justifyContent: 'space-between'}}>
              <label>English</label>
            <input  onChange={(e) => {setLanguage(e.target.value) }}  checked={chosenCountry === "english"? true: false} type="radio" value="english" name="group1"/>
            </div>
     
            <div style={{ display:'flex', justifyContent: 'space-between', marginTop:20}}>
              <label>Arabic</label>
              <input  onChange={(e) => {setLanguage(e.target.value) }} checked={chosenCountry === "arabic"? true: false} type="radio" value="arabic" name="group1"/>

            </div>
            </div>

          </div>

        </AccordionItem>

      </Accordion>
      </div> */}
      </Page>

      {/* </DirectionProvider> */}
    </>
  );
};

export default ManageScreen;
