import { useSelector } from "react-redux";
import { Menu, Page, TopNav } from "../../shared/components";
import { useMemo } from "react";
import { COUNTRIES } from "../../constants/appConstants";

// import {ManageScreen as events} from "../../analytics/events";
// import zigiChatBotInit from "../../lib/zigiChatBot";

const PRIVACY_NOTICES = {
  gha: {
    url: {
      en: "https://mtn.com.gh/data-protection-and-privacy-statement/",
    },
    title: {
      en: "MTN Ghana Privacy Notice",
      fr: "Avis de confidentialité de MTN Ghana",
    },
  },
  zmb: {
    url: {
      en: "https://www.mtn.zm/public-privacy-notice/",
    },
    title: {
      en: "MTN Zambia Privacy Notice",
      fr: "Avis de confidentialité de MTN Zambie",
    },
  },
  cmr: {
    url: {
      en: "https://mtn.cm/privacy-policy/",
      fr: "/privacyNotice",
    },
    title: {
      en: "MTN Cameroon Privacy Notice",
      fr: "Avis de confidentialité de MTN Cameroun",
    },
  },
  cog: {
    url: {
      en: "https://www.mtn.cg/politique-de-confidentialite/",
      fr: "https://www.mtn.cg/politique-de-confidentialite/",
    },
    title: {
      en: "MTN Congo Privacy Notice",
      fr: "Avis de confidentialité de MTN Congo",
    },
  },
  civ: {
    url: {
      en: "/privacyNotice",
      fr: "/privacyNotice",
    },
    title: {
      en: "MTN Côte d'Ivoire Privacy Notice",
      fr: "Avis de confidentialité de MTN Côte d'Ivoire",
    },
  },
  swz: {
    url: {
      en: "/privacyNotice",
    },
    title: {
      en: "MTN Eswatini Privacy Notice",
    },
  },
  uga: {
    url: {
      en: "/privacyNotice",
    },
    title: {
      en: "MTN Uganda Privacy Notice",
    },
  },
};

const PrivacyNoticesScreen = () => {
  const { network, language } = useSelector((state) => state);
  const { call } = network;
  const privacyNotice =
    PRIVACY_NOTICES[call?.headers?.region?.toLowerCase()] ?? null;
  const { chosenLanguage } = language;

  const onClickOpenA = () => {
    window.open("https://www.mtn.com/legal/?tablink=privacy_policy", "_blank");
  };

  const onClickOpenB = () => {
    if (privacyNotice) {
      if (privacyNotice.url[chosenLanguage?.code] === "/privacyNotice") {
        window.location.href = "/privacyNotice";
      } else {
        window.open(privacyNotice.url[chosenLanguage?.code], "_blank");
      }
    }
  };

  const countryName = useMemo(() => {
    try {
      return Object.entries(COUNTRIES).find((country) => {
        const value = country[1];
        return value.iso3 === call?.headers?.region;
      })?.[1]?.name;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [call?.headers?.region]);

  return (
    <>
      {/* <DirectionProvider direction={language?.chosenLanguage === 'arabic' ? DIRECTIONS.RTL:DIRECTIONS.LTR}> */}
      <Page isBottomBar={false}>
        <TopNav
          title={
            language?.chosenPack?.language?.COPY?.SCREENS?.manage
              ?.privacy_policy?.title
          }
          enableSearch={false}
          showCloseIcon={false}
          centerTitle={true}
        />
        <div style={{ margin: 25 }}>
          <p>
            {`${
              language?.chosenPack?.language?.COPY?.SCREENS?.manage
                ?.privacy_policy?.sub_text1
            } ${countryName || call?.headers?.region}
            . ${
              language?.chosenPack?.language?.COPY?.SCREENS?.manage
                ?.privacy_policy?.sub_text2
            }`}
          </p>
        </div>
        <div onClick={() => onClickOpenA()}>
          <Menu
            title={
              language?.chosenPack?.language?.COPY?.SCREENS?.manage
                ?.privacy_policy?.group_privacy_policy_title
            }
            clickRoute={null}
            description={""}
          />
        </div>
        <div onClick={() => onClickOpenB()}>
          <Menu
            title={
              privacyNotice && privacyNotice.title[chosenLanguage.code ?? "en"]
            }
            clickRoute={null}
            description={""}
          />
        </div>
      </Page>
    </>
  );
};

export default PrivacyNoticesScreen;
